import React from "react";

const InsightPageContent = ({ insightText, fullWidth }) => {
  console.log("full width is", insightText, fullWidth);
  return (
    <div className="section">
      <div className="container">
        <div
          className={`insight-page-content ${
            fullWidth === true ? "width-80-wrapper" : ""
          }`}
        >
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: insightText.html }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default InsightPageContent;
