import React from "react";
import InsightsPageItem from "../../components/atoms/InsightsPageItem";

const InsightPageRelated = (relatedBlogs) => {
  return (
    <>
      {relatedBlogs.length < 0 && (
        <div className="section m-top-10">
          <div className="container">
            <div className="insight-page-related">
              <div className="heading">RELATED BLOGS</div>
              <div className="insight-page-blogs-wrapper m-top-20px">
                {relatedBlogs &&
                  relatedBlogs.length > 0 &&
                  relatedBlogs.map(() => (
                    <InsightsPageItem
                      heading="Goken America Summer Internship : CHRIS EMERSON"
                      date="JUNE 15 2016"
                      para="For one thing they usually step all over the hedges and plants on the side of someone’s house killing them and setting back.."
                      url="/"
                    ></InsightsPageItem>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InsightPageRelated;
